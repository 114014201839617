import React from 'react';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const ContactForm = () => {
  useEffect(() => {
    document.title = 'ADO | Contact Us';
  }, []);
  return (
    <div className='content' style={{
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Container style={{ maxWidth: '750px' }}>
        <Typography variant="header">Contact the ADO</Typography>
        <Typography variant="subheader">To contact us, get in touch by email or social media</Typography>
        <br></br>
        <Typography variant="footer" style={{ textAlign: 'center' }}>
          Contact@AmateurDarts.org
        </Typography>
        <br></br>
        <Typography className="link" style={{ textAlign: 'center' }}>
          <a href="https://www.facebook.com/@AmateurDartsOrganisation"><FacebookIcon style={{ marginRight: 4 }} />Facebook</a>
        </Typography>
        <Typography className="link" style={{ textAlign: 'center' }}>
          <a href="https://twitter.com/AmateurDartsOrg"><TwitterIcon style={{ marginRight: 4 }} />Twitter</a>
        </Typography>
        <Typography className="link" style={{ textAlign: 'center' }}>
          <a href="https://www.instagram.com/amateurdartsorg/"><InstagramIcon style={{ marginRight: 4 }} />Instagram</a>
        </Typography>
      </Container>
    </div>

  );
};

export default ContactForm;