import React from 'react';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = 'ADO | Privacy Policy';
  }, []);

  return (
    <div className='content'>
      <Typography variant="header">
        ADO Website Privacy Policy
      </Typography>

      <div style={{ maxWidth: '750px', margin: 'auto', textAlign: 'left' }}>

        <Typography variant="policysubheader">Last Updated: 7th January 2024</Typography>

        <Typography variant="policy">
          Thank you for visiting The Amateur Darts Organisation's website. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information. By accessing or using our website, you agree to the terms of this Privacy Policy.
        </Typography>

        <Typography variant="policyheader">
          1. Information We Collect:
        </Typography>

        <Typography variant="policy">
          1.1. Personal Information:
        </Typography>
        <Typography variant="policy">
          We may collect personal information, such as your name, email address, phone number, and other contact details when you voluntarily provide them through forms on our website.
        </Typography>

        <Typography variant="policy">
          1.2. Usage Data:
        </Typography>
        <Typography variant="policy">
          We may automatically collect information about your visit, including your IP address, browser type, device information, and the pages you access on our website. This information helps us analyze trends, administer the site, and improve user experience.
        </Typography>

        <Typography variant="policyheader">
          2. How We Use Your Information:
        </Typography>

        <Typography variant="policy">
          2.1. Providing Services:
        </Typography>
        <Typography variant="policy">
          We use the information you provide to deliver our services, respond to inquiries, and communicate with you regarding your membership or participation in The Amateur Darts Organisation.
        </Typography>

        <Typography variant="policy">
          2.2. Analytics and Improvements:
        </Typography>
        <Typography variant="policy">
          We may use the collected data to analyze website usage patterns, improve our content, and enhance user experience.
        </Typography>

        <Typography variant="policyheader">
          3. Cookies and Tracking Technologies:
        </Typography>

        <Typography variant="policy">
          3.1. Cookies:
        </Typography>
        <Typography variant="policy">
          Our website may use cookies and similar tracking technologies to enhance user experience and collect information about how you use the site. You can manage your cookie preferences through your browser settings.
        </Typography>

        <Typography variant="policyheader">
          4. Sharing Your Information:
        </Typography>

        <Typography variant="policy">
          4.1. Third-Party Service Providers:
        </Typography>
        <Typography variant="policy">
          We may share your information with third-party service providers who assist us in website management, analytics, or other functions.
        </Typography>

        <Typography variant="policy">
          4.2. Legal Compliance:
        </Typography>
        <Typography variant="policy">
          We may disclose your information if required by law or in response to legal processes.
        </Typography>

        <Typography variant="policyheader">
          5. Security:
        </Typography>

        <Typography variant="policy">
          5.1. Security Measures:
        </Typography>
        <Typography variant="policy">
          We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.
        </Typography>

        <Typography variant="policyheader">
          6. Your Choices:
        </Typography>

        <Typography variant="policy">
          6.1. Opt-Out:
        </Typography>
        <Typography variant="policy">
          You may opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in our emails.
        </Typography>

        <Typography variant="policyheader">
          7. Changes to this Privacy Policy:
        </Typography>

        <Typography variant="policy">
          7.1. Updates:
        </Typography>
        <Typography variant="policy">
          We reserve the right to update this Privacy Policy. Any changes will be effective upon posting the revised policy on our website.
        </Typography>

        <Typography variant="policyheader">
          8. Contact Us:
        </Typography>

        <Typography variant="policy">
          If you have any questions or concerns about our Privacy Policy, please contact us at [contact@amateurdarts.org].
        </Typography>

        <Typography variant="policy" style={{ fontWeight: 'bold', paddingTop: '10px', paddingBottom: '10px' }}>
          By using our website, you agree to the terms outlined in this Privacy Policy.
        </Typography>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
