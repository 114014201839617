import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Menu, List, ListItem, Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function NavBar() {
  const [playersAnchorEl, setPlayersAnchorEl] = useState(null);
  const [tournamentsAnchorEl, setTournamentsAnchorEl] = useState(null);
  const [extraAnchorEl, setExtraAnchorEl] = useState(null);

  const handlePlayersClick = (event) => {
    setPlayersAnchorEl(event.currentTarget);
  };

  const handleTournamentsClick = (event) => {
    setTournamentsAnchorEl(event.currentTarget);
  };

  const handleExtraClick = (event) => {
    setExtraAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setPlayersAnchorEl(null);
    setTournamentsAnchorEl(null);
    setExtraAnchorEl(null);
  };

  const navOptions = [
    { label: 'Home', to: '/' }
  ];

  const extraOptions = [
    { label: 'About Us', to: '/aboutus' },
    { label: 'Contact Us', to: '/contactus' }
  ];

  const playersOptions = [
    { label: 'Order of Merit', to: '/rankings' },
    { label: 'Player Profiles', to: '/players' },
  ];

  const tournamentsOptions = [
    { label: 'Tournament Results', to: '/tournaments' },
    { label: 'Upcoming Events', to: '/events' },
    { label: 'Premier League', to: '/premierleague' }
  ];

  return (
    <nav>
      <ul>

        {navOptions.map((option, index) => (
          <li key={index}>
            <NavLink exact className="navlink" activeClassName="nav_link--active" to={option.to} style={{ padding: '1vh 2vw', color: 'white', backgroundColor: '#006494' }}>
              <Typography variant="navigation">{option.label}</Typography>
            </NavLink>
          </li>
        ))}

        <Button
          style={{ display: 'inline', textTransform: 'none', fontWeight: 'normal', textDecoration: 'none', color: 'white', backgroundColor: playersAnchorEl ? '#0582CA' : '#006494', borderRadius: 0 }}
          className={`${playersAnchorEl ? 'nav_link--active' : ''}`}
          onClick={handlePlayersClick}>
          <Typography variant="navigation">Players</Typography>
          <ArrowDropDownIcon style={{ color: 'white', marginLeft: '4px' }} />
        </Button>

        <Button
          style={{ display: 'inline', textTransform: 'none', fontWeight: 'normal', textDecoration: 'none', color: 'white', backgroundColor: tournamentsAnchorEl ? '#0582CA' : '#006494', borderRadius: 0 }}
          className={`${tournamentsAnchorEl ? 'nav_link--active' : ''}`}
          onClick={handleTournamentsClick}>
          <Typography variant="navigation">Tournaments</Typography>
          <ArrowDropDownIcon style={{ color: 'white', marginLeft: '4px' }} />
        </Button>

        <Button
          style={{ display: 'inline', textTransform: 'none', fontWeight: 'normal', textDecoration: 'none', color: 'white', backgroundColor: extraAnchorEl ? '#0582CA' : '#006494', borderRadius: 0 }}
          className={`${extraAnchorEl ? 'nav_link--active' : ''}`}
          onClick={handleExtraClick}>
          <Typography variant="navigation">Other</Typography>
          <ArrowDropDownIcon style={{ color: 'white', marginLeft: '4px' }} />
        </Button>

      </ul>

      <Menu
        anchorEl={extraAnchorEl}
        open={Boolean(extraAnchorEl)}
        onClose={handleDropdownClose}>
        <List className="dropdown-list">
          {extraOptions.map((option, index) => (
            <ListItem key={index} button onClick={handleDropdownClose} style={{ padding: '0.5vh 1vw' }}>
              <NavLink exact className="navlink" activeClassName="nav_link--active" to={option.to}>
                <Typography variant="navigation">{option.label}</Typography>
              </NavLink>
            </ListItem>
          ))}
        </List>
      </Menu>

      <Menu
        anchorEl={playersAnchorEl}
        open={Boolean(playersAnchorEl)}
        onClose={handleDropdownClose}>
        <List className="dropdown-list">
          {playersOptions.map((option, index) => (
            <ListItem key={index} button onClick={handleDropdownClose} style={{ padding: '0.5vh 1vw' }}>
              <NavLink exact className="navlink" activeClassName="nav_link--active" to={option.to}>
                <Typography variant="navigation">{option.label}</Typography>
              </NavLink>
            </ListItem>
          ))}
        </List>
      </Menu>

      <Menu
        anchorEl={tournamentsAnchorEl}
        open={Boolean(tournamentsAnchorEl)}
        onClose={handleDropdownClose}>
        <List className="dropdown-list">
          {tournamentsOptions.map((option, index) => (
            <ListItem key={index} button onClick={handleDropdownClose} style={{ padding: '0.5vh 1vw' }}>
              <NavLink exact className="navlink" activeClassName="nav_link--active" to={option.to}>
                <Typography variant="navigation">{option.label}</Typography>
              </NavLink>
            </ListItem>
          ))}
        </List>
      </Menu>

    </nav>
  );
}

export default NavBar;