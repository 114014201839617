import React from 'react';
import logo from './images/ADO_Wide_Logo.png';
import './style/Header.css';

// Header Component
function Header() {
  return (
    <header className="header">
      <img className="header_logo" src={logo} alt="ADO lOGO"></img>
    </header>
  );
}

export default Header;