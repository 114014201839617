import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { db } from './components/firebase/firebase-config.js';
import { doc, getDoc } from '@firebase/firestore';

interface ExpandMoreProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Tournaments() {
  useEffect(() => {
    document.title = 'ADO | Tournament Results';
  }, []);
  const [tournamentData, setTournamentData] = useState({});
  const [expandedMatches, setExpandedMatches] = useState({});
  const [expandedPrizeMoney, setExpandedPrizeMoney] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tournamentDocumentRef = doc(db, 'ado_database', 'tournaments');
        const tournamentDocSnap = await getDoc(tournamentDocumentRef);

        if (tournamentDocSnap.exists()) {
          const rawData = tournamentDocSnap.data();

          if (typeof rawData === 'object' && rawData !== null) {
            setTournamentData(rawData);
            const initialMatchesExpandedState = {};
            const initialPrizeMoneyExpandedState = {};
            Object.keys(rawData).forEach((compId) => {
              initialMatchesExpandedState[compId] = false;
              initialPrizeMoneyExpandedState[compId] = false;
            });
            setExpandedMatches(initialMatchesExpandedState);
            setExpandedPrizeMoney(initialPrizeMoneyExpandedState);
            setLoading(false);
          } else {
            console.log('Tournament data is not an object.');
          }
        } else {
          console.log('No tournaments found!');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleMatchesExpandClick = (compId) => {
    setExpandedMatches((prevExpandedMatches) => ({
      ...prevExpandedMatches,
      [compId]: !prevExpandedMatches[compId],
    }));
  };

  const handlePrizeMoneyExpandClick = (compId) => {
    setExpandedPrizeMoney((prevExpandedPrizeMoney) => ({
      ...prevExpandedPrizeMoney,
      [compId]: !prevExpandedPrizeMoney[compId],
    }));
  };

  if (loading) {
    return (
      <div className="content">
        <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />
      </div>)
  }

  return (
    <div className="content">
      {Object.keys(tournamentData).map((compId) => (
        <Card key={compId} elevation={3} className={`event_paper row${parseInt(compId, 10) + 1}`} style={{ height: 'auto', overflow: 'hidden', maxWidth: '450px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '1.5vh', position: 'relative', boxSizing: 'border-box' }}>
          <Grid item xs={12} style={{ backgroundColor: '#006494', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))' }}>
            <Typography variant="infoheader" style={{ color: '#ffffff', padding: '1vh' }}>{tournamentData[compId].event_details.competitionName}</Typography>
          </Grid>
          <Grid container spacing={1} style={{ flex: 1, padding: '1vh' }}>
            <Grid item xs={12}>
              <Typography variant="infoheader">Venue</Typography>
              <Typography variant="info">{tournamentData[compId].event_details.venue}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="infoheader">Players</Typography>
              <Typography variant="info">{tournamentData[compId].event_details.players}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="infoheader">Points Pot</Typography>
              <Typography variant="info">{new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, }).format(tournamentData[compId].event_details.prizepot)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="infoheader">Event Date</Typography>
              <Typography variant="info">{new Date(tournamentData[compId].event_details.startDate).toLocaleDateString('en-GB')}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="infoheader">Points Expiry</Typography>
              <Typography variant="info">{new Date(tournamentData[compId].event_details.endDate).toLocaleDateString('en-GB')}</Typography>
            </Grid>
          </Grid>

          <CardActions disableSpacing style={{ backgroundColor: '#0582CA', color: '#ffffff', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))', padding: '0.5vh' }}>
            <Typography variant="info">Match Results</Typography>
            <ExpandMore
              expand={expandedMatches[compId] || false}
              onClick={() => handleMatchesExpandClick(compId)}
            >
              <ExpandMoreIcon style={{ color: '#ffffff' }} />
            </ExpandMore>
          </CardActions>
          <Collapse in={expandedMatches[compId]} timeout="auto" unmountOnExit>
            <CardContent style={{ padding: '1vh' }}>
              <Grid container>
                {tournamentData[compId].matches &&
                  tournamentData[compId].matches.map((match, matchIndex) => {
                    const matchCategory = match.matchDetails ? match.matchDetails[7] : 'No Round';

                    if (matchIndex === 0 || matchCategory !== tournamentData[compId].matches[matchIndex - 1].matchDetails[7]) {
                      return (
                        <Grid container spacing={1} key={matchIndex}>
                          <Grid item xs={12}>
                            <Typography variant="infoheader">
                              {matchCategory}
                            </Typography>
                            <Typography variant="info">Best of {match.matchDetails[12]} legs</Typography>
                          </Grid>
                          <Grid item xs={5.25}>
                            <Typography>{match.matchDetails[0]}</Typography>
                          </Grid>
                          {match.matchDetails[8] === match.matchDetails[10] ? (
                            <>
                              <Grid item xs={0.5}>
                                <Typography style={{ fontWeight: 'bold' }}>{match.matchDetails[1]}</Typography>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={0.5}>
                                <Typography>{match.matchDetails[1]}</Typography>
                              </Grid>
                            </>
                          )}
                          <Grid item xs={0.5}>
                            <Typography> - </Typography>
                          </Grid>
                          {match.matchDetails[9] === match.matchDetails[10] ? (
                            <>
                              <Grid item xs={0.5}>
                                <Typography style={{ fontWeight: 'bold' }}>{match.matchDetails[3]}</Typography>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={0.5}>
                                <Typography>{match.matchDetails[3]}</Typography>
                              </Grid>
                            </>
                          )}
                          <Grid item xs={5.25}>
                            <Typography>{match.matchDetails[2]}</Typography>
                          </Grid>
                        </Grid>
                      );
                    }

                    return (
                      <Grid container key={matchIndex}>
                        <Grid item xs={5.25}>
                          <Typography>{match.matchDetails[0]}</Typography>
                        </Grid>
                        {match.matchDetails[8] === match.matchDetails[10] ? (
                          <>
                            <Grid item xs={0.5}>
                              <Typography style={{ fontWeight: 'bold' }}>{match.matchDetails[1]}</Typography>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={0.5}>
                              <Typography>{match.matchDetails[1]}</Typography>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={0.5}>
                          <Typography> - </Typography>
                        </Grid>
                        {match.matchDetails[9] === match.matchDetails[10] ? (
                          <>
                            <Grid item xs={0.5}>
                              <Typography style={{ fontWeight: 'bold' }}>{match.matchDetails[3]}</Typography>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={0.5}>
                              <Typography>{match.matchDetails[3]}</Typography>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={5.25}>
                          <Typography>{match.matchDetails[2]}</Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            </CardContent>
          </Collapse>

          <CardActions disableSpacing style={{ backgroundColor: '#00A6FB', color: '#ffffff', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))', padding: '0.5vh' }}>
            <Typography variant="info">Prize Points</Typography>
            <ExpandMore
              expand={expandedPrizeMoney[compId] || false}
              onClick={() => handlePrizeMoneyExpandClick(compId)}
            >
              <ExpandMoreIcon style={{ color: '#ffffff' }} />
            </ExpandMore>
          </CardActions>
          <Collapse in={expandedPrizeMoney[compId]} timeout="auto" unmountOnExit>
            <CardContent style={{ padding: '1vh' }}>
              {tournamentData[compId].prize_money &&
                tournamentData[compId].prize_money.map((prize, prizeIndex) => {
                  const currentPrizeDetails3 = prize.prizeDetails ? prize.prizeDetails[3] : '';
                  const currentPrizeDetails4 = prize.prizeDetails ? prize.prizeDetails[4] : '';

                  const previousIndex = prizeIndex - 1;
                  const previousPrizeDetails3 = previousIndex >= 0
                    ? tournamentData[compId].prize_money[previousIndex].prizeDetails[3]
                    : '';
                  const previousPrizeDetails4 = previousIndex >= 0
                    ? tournamentData[compId].prize_money[previousIndex].prizeDetails[4]
                    : '';

                  const shouldListPrizeDetails3 = currentPrizeDetails3 !== previousPrizeDetails3;
                  const shouldListPrizeDetails4 = currentPrizeDetails4 !== previousPrizeDetails4;

                  return (
                    <Grid container spacing={4} key={prizeIndex}>
                      <Grid item xs={12}>
                        {prize.prizeDetails && (
                          <>
                            {shouldListPrizeDetails4 && (
                              <Grid item xs={12}>
                                <Typography variant="infoheader" style={{ paddingTop: '0.5vh', paddingBottom: '0.25vh' }}>
                                  {prize.prizeDetails[4]}
                                </Typography>
                              </Grid>
                            )}

                            {shouldListPrizeDetails3 && (
                              <Grid item xs={12}>
                                <Typography variant="infobold" style={{ paddingTop: '0.25vh', paddingBottom: '0.5vh' }}>
                                  {new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0, }).format(prize.prizeDetails[3])} pts
                                </Typography>
                              </Grid>
                            )}

                            <Grid item xs={12}>
                              <Typography variant="info" style={{ textAlign: 'center' }}>
                                {prize.prizeDetails[0]}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </div>
  );
}

export default Tournaments;