import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header.js';
import NavBar from './components/NavBar.js';
import Footer from './components/Footer.js';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';
import ADOtheme from '../pages/components/theme/theme.js';

const Layout = () => {
  return (
    <>
      <ThemeProvider theme={ADOtheme}>
        <Header />
        <NavBar />
        <Grid
          item
          xs={12}
          style={{ padding: '0.25vh', backgroundColor: '#0582CA' }}
        ></Grid>
        <Outlet />
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default Layout;