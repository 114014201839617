import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const YouTubeLatestVideo = () => {
  const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY; // Replace with your actual API key
  const channelId = process.env.REACT_APP_YOUTUBE_CHANNEL_ID;
  const [latestVideo, setLatestVideo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLatestVideo = async () => {
      try {
        const channelInfoResponse = await fetch(
          `https://www.googleapis.com/youtube/v3/channels?part=contentDetails&id=${channelId}&key=${API_KEY}`
        );

        const channelInfoData = await channelInfoResponse.json();
        const uploadsPlaylistId = channelInfoData.items[0]?.contentDetails?.relatedPlaylists?.uploads;

        const latestVideoResponse = await fetch(
          `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${uploadsPlaylistId}&maxResults=1&key=${API_KEY}`
        );

        const latestVideoData = await latestVideoResponse.json();
        const videoSnippet = latestVideoData.items[0]?.snippet;

        setLatestVideo(videoSnippet);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching YouTube data:', error);
        setLoading(false);
      }
    };

    fetchLatestVideo();
  }, [API_KEY, channelId]);

  return (
    <Grid container spacing={0} style={{ maxWidth: '500px', width: '90%', margin: 'auto', boxSizing: 'border-box', padding: '1vh'}}>
      {loading ? (
        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '50px' }}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="header">ADO Socials</Typography>
            <Typography variant="subheader">Latest YouTube video</Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', marginTop: '1vh' }}>
              <iframe
                title={latestVideo.title}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                src={`https://www.youtube.com/embed/${latestVideo.resourceId.videoId}`}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default YouTubeLatestVideo;
