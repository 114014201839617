import React from 'react';
import UnderConstruction from './components/underConstruction';
import Typography from '@mui/material/Typography';
import { Paper, Grid } from '@mui/material';

function PremierLeague() {
    return (
        <div className="content">
            <UnderConstruction />
        </div>
    );
}

export default PremierLeague;