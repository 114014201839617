import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { db } from './components/firebase/firebase-config.js';
import { doc, getDoc } from '@firebase/firestore';
import MyComponent from './components/playerProfile.js';

function Players() {
  useEffect(() => {
    document.title = 'ADO | Players';
  }, []);

  const [playerData, setPlayerData] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogPlayerId, setDialogPlayerId] = useState('');
  const [dialogPlayerNickname, setDialogPlayerNickname] = useState('');
  const [dialogPlayerName, setDialogPlayerName] = useState('');
  const [dialogPlayerDarts, setDialogPlayerDarts] = useState('');
  const [dialogPlayerWalkOut, setDialogPlayerWalkOut] = useState('');
  const [dialogPlayerPhotoPath, setDialogPlayerPhotoPath] = useState('');

  const playersDocumentRef = doc(db, 'ado_database', 'players');

  useEffect(() => {
    const getPlayerData = async () => {
      try {
        const docSnap = await getDoc(playersDocumentRef);

        if (docSnap.exists()) {
          const documentData = { ...docSnap.data(), id: docSnap.id };

          const playerArray = Object.keys(documentData)
            .filter(rowKey => rowKey !== 'id')
            .map(rowKey => ({
              key: rowKey,
              playerName: documentData[rowKey][2].toLowerCase(),
              playerInfo: documentData[rowKey],
            }))
            .sort((a, b) => a.playerName.localeCompare(b.playerName));

          setPlayerData(playerArray);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error getting document:', error);
      }
    };
    getPlayerData();
  }, []);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogPlayerId('');
    setDialogPlayerNickname('');
    setDialogPlayerName('');
    setDialogPlayerDarts('');
    setDialogPlayerWalkOut('');
    setDialogPlayerPhotoPath('');
  };

  const handleOpenDialog = (playerId, playerNickname, playerName, playerDarts, playerWalkOut, playerPhotoPath) => {
    setDialogOpen(true);
    setDialogPlayerId(playerId);
    setDialogPlayerNickname(playerNickname);
    setDialogPlayerName(playerName);
    setDialogPlayerDarts(playerDarts);
    setDialogPlayerWalkOut(playerWalkOut);
    setDialogPlayerPhotoPath(playerPhotoPath);
  };

  return (
    <div className="content">
      <Typography variant="header">Registered Players</Typography>
      <Typography variant="subheader" style={{ marginBottom: '2vh' }}>
        A stats breakdown for each active player in the ADO
      </Typography>
      <Grid container style={{ flex: 1, margin: 'auto' }}>
        {playerData.map(({ key, playerInfo }) => {
          const playerId = playerInfo[0];
          const playerNickname = playerInfo[3];
          const playerName = playerInfo[5];
          const playerDarts = playerInfo[6];
          const playerWalkOut = playerInfo[7];
          const playerPhotoPath = playerInfo[8];

          return (
            <Grid item xs={4} style={{ minWidth: '265px', width: '100%', margin: 'auto' }}>
              <Paper
                key={key}
                elevation={3}
                className={`player_paper row${parseInt(key, 10) + 1}`}
                style={{
                  flex: 1,
                  height: 'auto',
                  overflow: 'hidden',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  position: 'relative',
                  width: '95%',
                  marginBottom: '1vh',
                }}
              >
                <Grid container spacing={0} style={{ flex: 1, height: '5vh', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.15),rgba(0, 0, 0, 0.15))' }}>
                  <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', overflow: 'hidden' }}>
                    <Typography variant="statname" style={{ textAlign: 'left', paddingLeft: '1vh', paddingRight: '1vh' }}>{`${playerName}`}</Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 'auto', width: 'auto', height: 'auto' }}>
                    <Link
                      onClick={() => handleOpenDialog(playerId, playerNickname, playerName, playerDarts, playerWalkOut, playerPhotoPath)}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        paddingLeft: '1.25vh',
                        paddingRight: '1.25vh',
                        height: '100%',
                        fontSize: '1.5vh',
                        backgroundColor: '#006494',
                        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))',
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                      }}
                    >
                      <Typography style={{ color: '#ffffff' }}>Profile</Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      <MyComponent isOpen={isDialogOpen} handleClose={handleCloseDialog} playerId={dialogPlayerId} playerNickname={dialogPlayerNickname} playerName={dialogPlayerName} playerDarts={dialogPlayerDarts} playerWalkOut={dialogPlayerWalkOut} playerPhotoPath={dialogPlayerPhotoPath} />
    </div>
  );
}

export default Players;
