import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { db } from './components/firebase/firebase-config.js';
import { doc, getDoc } from '@firebase/firestore';
import placeholderImage from './components/photo/placeholder.png';
import glassOverlay from './components/images/glass-overlay.png';
import usePlayerImage from './components/usePlayerImage'; // Import the custom hook
import playerBackground from './components/photo/player-background.png';
import { CircularProgress } from '@mui/material';

// Define the Rankings component
function Rankings() {
  useEffect(() => {
    document.title = 'ADO | Order of Merit';
  }, []);

  const [imageError, setImageError] = useState(false);
  const [rankingData, setRankingData] = useState({});

  // Update the document path
  const rankingDocumentRef = doc(db, 'ado_database', 'ranking');

  useEffect(() => {
    const getRankingData = async () => {
      try {
        const docSnap = await getDoc(rankingDocumentRef);

        if (docSnap.exists()) {
          const documentData = { ...docSnap.data(), id: docSnap.id };
          setRankingData(documentData);
        } else {
          console.log('No such document!');
        } 
      } catch (error) {
        console.error('Error getting document:', error);
      }
    };

    getRankingData();
  }, []); // Empty dependency array, so it only runs once when the component mounts

  return (
    <div className="content">
      <Typography variant="header">Order of Merit</Typography>
      <Typography variant="subheader">By competing in official events players earn prize points which are valid for one year</Typography>
      <Paper elevation={6} variant="table_head" style={{marginTop: "1vh"}}>
        <Grid container spacing={0} alignItems="center" height="4vh" backgroundColor="#000000">
          <Grid item xs={1.5} style={{ backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))', padding: '10px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography style={{ fontSize: '1.5vh', color: '#ffffff'}}>Rank</Typography>
          </Grid>
          <Grid item xs={6.5} style={{backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))', padding: '10px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography style={{ fontSize: '1.5vh', color: '#ffffff'}}>Player & Points</Typography>
          </Grid>
          <Grid item xs={4} style={{ backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))', padding: '10px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography style={{ fontSize: '1.5vh', color: '#ffffff'}}>Photo</Typography>
          </Grid>
        </Grid>
      </Paper>
      {Object.keys(rankingData).map((rowKey, index) => {
        if (rowKey !== "id") {
          return (
            <Paper key={rowKey} elevation={6} className={`ranking_paper row${parseInt(rowKey, 10) + 1}`} style={{marginBottom: '1.25vh'}}>
              <Grid container spacing={0} alignItems="center" height="15vh">
                <Grid item xs={1.5} style={{ backgroundColor: '#051923', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))', padding: '10px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography style={{ fontSize: '2vh', color: '#ffffff', fontWeight: 'bold' }}>{`${rankingData[rowKey][0]}`}</Typography>
                </Grid>
                <Grid item xs={6.5} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Grid container spacing={0} alignItems="center" height="15vh" width="100%" style={{ margin: 'auto' }}>
                    <Grid style={{ backgroundColor: '#006494', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))', height: '5vh', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <Typography style={{ fontSize: '3vh', color: '#ffffff', width: '100%' }}>{`${rankingData[rowKey][1]}`}</Typography>
                    </Grid>
                    <Grid style={{ backgroundColor: '#0582CA', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))', height: '4vh', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <Typography style={{ fontSize: '2.5vh', color: '#ffffff', width: '100%' }}>{`${rankingData[rowKey][2]}`}</Typography>
                    </Grid>
                    <Grid style={{ backgroundColor: '#ffffff', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))', height: '6vh', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <Typography style={{ fontSize: '2.25vh', width: '100%' }}>{`${Math.round(rankingData[rowKey][3]).toLocaleString()}`}</Typography>
                      <Typography style={{ fontSize: '1.5vh', width: '100%' }}>{`ADO Points`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} style={{ backgroundImage: `url(${playerBackground})`, overflow: 'hidden', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                  <div style={{ width: "100%", height: '15vh', overflow: "hidden", margin: "auto", borderLeft: '0.5px solid #000000'}}>
                    <ImageLoader
                      fileName={rankingData[rowKey][4]}
                      alt={`${rankingData[rowKey][1]}'s Photo`}
                      placeholder={placeholderImage}
                      setImageError={setImageError}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="overlay-image">
                <img src={glassOverlay} style={{ position: 'absolute', top: '0', left: '0', width: '100%', maxWidth: '450px', height: '15vh', backgroundSize: 'cover', opacity: '0.5' }} />
              </div>
            </Paper>
          );
        }
        return null;
      })}
    </div>
  );
}

// Define the ImageLoader component
const ImageLoader = ({ fileName, alt, placeholder, setImageError }) => {
  const { loading, error, image } = usePlayerImage(fileName);

  useEffect(() => {
  }, [image]);

  if (error) return (
    <img
      className="Image"
      src={placeholderImage}
      alt={alt}
      style={{margin: 'auto', width: '125%', height: 'auto', objectFit: 'cover', transform: 'translateX(-10%) translateY(-10%)', }}
    />
  );

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <img
          className="Image"
          src={image}
          alt={alt}
          style={{margin: 'auto', width: '110%', height: 'auto', objectFit: 'cover', transform: 'translateX(-5%) translateY(-10%)', }}
        />
      )}
    </>
  );
};

export default Rankings;
