import { createTheme } from '@mui/material/styles';

const ADOtheme = createTheme({
    typography: {
        fontFamily: 'Tw Cen MT Std, sans-serif',
        fontSize: '2vh',
        lineHeight: 1,
        header: {
            fontFamily: 'Tw Cen MT Std, sans-serif',
            fontSize: '3.5vh',
            display: 'block',
            lineHeight: 1.3,
        },
        subheader: {
            fontFamily: 'Tw Cen MT Std, sans-serif',
            fontSize: '2.75vh',
            display: 'block',
            lineHeight: 1.2,
        },
        navigation: {
            fontFamily: 'Tw Cen MT Std, sans-serif',
            fontSize: '2.5vh',
        },
        footer: {
            fontFamily: 'Tw Cen MT Std, sans-serif',
            fontSize: 'vh',
            display: 'block',
            lineHeight: 1.2,
        },
        stat: {
            fontFamily: 'Tw Cen MT Std, sans-serif',
            fontSize: '4vh',
            display: 'block',
            textAlign: 'center',
        },
        smallstat: {
            fontFamily: 'Tw Cen MT Std, sans-serif',
            fontSize: '3vh',
            display: 'block',
            textAlign: 'center',
            padding: '0.5vh'
        },
        statname: {
            fontFamily: 'Tw Cen MT Std, sans-serif',
            fontSize: '2.5vh',
            display: 'block',
            textAlign: 'center',
            padding: '0.5vh',
        },
        stathead: {
            fontFamily: 'Tw Cen MT Std, sans-serif',
            fontSize: '2vh',
            display: 'block',
            textAlign: 'center',
            padding: '1vh',
        },
        infobold: {
            fontFamily: 'Tw Cen MT Std, sans-serif',
            fontSize: '2vh',
            display: 'block',
            lineHeight: 1.2,
            fontWeight: 'bold',
            textAlign: 'center'
        },
        info: {
            fontFamily: 'Tw Cen MT Std, sans-serif',
            fontSize: '2.25vh',
            display: 'block',
            lineHeight: 1.2,
            textAlign: 'center',
        },
        infoheader: {
            fontFamily: 'Tw Cen MT Std, sans-serif',
            fontSize: '3vh',
            display: 'block',
            lineHeight: 1.2,
        },
        policyheader: {
            fontFamily: 'Tw Cen MT Std, sans-serif',
            fontSize: '3vh',
            display: 'block',
            lineHeight: 2,
        },
        policysubheader: {
            fontFamily: 'Tw Cen MT Std, sans-serif',
            fontSize: '2.5vh',
            display: 'block',
            lineHeight: 1.75,
        },
        policy: {
            fontFamily: 'Tw Cen MT Std, sans-serif',
            fontSize: '2vh',
            display: 'block',
            lineHeight: 1.5,
        }
        // Add more custom variations as needed
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    height: 'auto',
                    overflow: 'hidden',
                    maxWidth: '450px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    position: 'relative',
                    marginBottom: '1vh'
                },
            },
        },
    },
    // ... other theme settings ...
});

export default ADOtheme;
