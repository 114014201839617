import React from 'react';
import { useEffect } from 'react';
import bounceOut from './components/images/bounce-out.png';
import Typography from '@mui/material/Typography';

const NotFound = () => {
  useEffect(() => {
    document.title = 'ADO | 404 Not Found';
  }, []);
  return (
    <div className='content' style={{
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', // Center horizontally
      justifyContent: 'center' // Center vertically
    }}>
      <Typography variant="header">Uh Oh... Bounce Out!</Typography>
      <Typography variant="header" style={{fontWeight: "bold"}}>Error 404</Typography>
      <Typography variant="subheader">The page you are looking for does not exist or could not be found</Typography>
      <img style={{ width: '25%', maxWidth: '100%', height: 'auto' }} src={bounceOut} alt="Bounce Out" />
    </div>
    
  );
};

export default NotFound;  