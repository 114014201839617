import React, { useEffect, useState } from 'react';
import { db } from './components/firebase/firebase-config.js';
import { doc, getDoc } from '@firebase/firestore';
import Grid from '@mui/material/Grid';
import Styles from '../pages/components/style/App.css';
import statRoundel from './components/images/stat_roundel.png';
import statContainer from './components/images/stat_container.png';
import { Typography } from '@mui/material';
import YouTubeLatestVideo from './components/youtubeComponent.js';

function Home() {
  useEffect(() => {
    document.title = 'ADO | Home';
  }, []);

  const [adoData, setADOData] = useState({});

  const adoDocumentRef = doc(db, 'ado_database', 'ado_stats');

  useEffect(() => {
    const getADOData = async () => {
      try {
        const docSnap = await getDoc(adoDocumentRef);

        if (docSnap.exists()) {
          const documentData = { ...docSnap.data(), id: docSnap.id };
          setADOData(documentData);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error getting document:', error);
      }
    };

    getADOData();
  }, []);

  return (
    <div className="content">
      <Typography variant="header">Quick Stats</Typography>
      <Typography variant="subheader">A summary of statistics from across the ADO</Typography>
      {Object.keys(adoData).map((rowKey) => {
        if (rowKey !== "id") {
          return (
            <div key={rowKey}>
              <Grid container spacing={0} style={{ margin: "auto", maxWidth: "600px", boxSizing: "border-box", height: "auto", padding: '1vh', paddingTop: '2vh' }}>

                <Grid container style={{ margin: "auto", height: "auto", width: "auto" }}>
                  <Grid item xs={12} style={{ height: '7vh', width: '7vh', backgroundImage: `url(${statRoundel})`, backgroundSize: "contain", backgroundPosition: 'center center', backgroundRepeat: "no-repeat", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="smallstat">{`${adoData[rowKey][7]}`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="info">Players</Typography>
                  </Grid>
                </Grid>

                <Grid container style={{ margin: "auto", height: "auto", width: "auto" }}>
                  <Grid item xs={12} style={{ height: '7vh', width: '7vh', backgroundImage: `url(${statRoundel})`, backgroundSize: "contain", backgroundPosition: 'center center', backgroundRepeat: "no-repeat", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="smallstat">{`${adoData[rowKey][2]}`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="info">Events</Typography>
                  </Grid>
                </Grid>

                <Grid container style={{ margin: "auto", height: "auto", width: "auto" }}>
                  <Grid item xs={12} style={{ height: '7vh', width: '7vh', backgroundImage: `url(${statRoundel})`, backgroundSize: "contain", backgroundPosition: 'center center', backgroundRepeat: "no-repeat", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="smallstat">{`${adoData[rowKey][0]}`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="info">Matches</Typography>
                  </Grid>
                </Grid>

              </Grid>

              <Grid container style={{ margin: "auto", maxWidth: "400px", boxSizing: "border-box", height: "auto", padding: '1vh', marginBottom: "2vh" }}>

                <Grid item xs={12}>
                  <Typography variant="subheader" style={{ paddingBottom: '2vh' }}>Next Official ADO Event</Typography>
                </Grid>

                <Grid item xs={8} style={{ height: '7vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant="statname" style={{ textAlign: 'left' }}>{adoData[rowKey][12]}</Typography>
                </Grid>


                <Grid item xs={4} style={{ height: '7vh', backgroundImage: `url(${statContainer})`, backgroundSize: "contain", backgroundPosition: 'center center', backgroundRepeat: "no-repeat", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant="statname">{adoData[rowKey][11] ? new Date(adoData[rowKey][11].seconds * 1000).toLocaleDateString('en-GB') : 'Invalid Date'}</Typography>
                </Grid>


              </Grid>

              <Grid item xs={12}>
                <Typography variant="subheader">ADO Top 3 Ranked Players</Typography>
              </Grid>

              <Grid container style={{ margin: "auto", height: "auto", maxWidth: "250px", paddingBottom: '1vh', paddingTop: '2vh' }}>
                <Grid item xs={4} style={{ height: '5vh', width: '5vh', backgroundImage: `url(${statRoundel})`, backgroundSize: "contain", backgroundPosition: 'center center', backgroundRepeat: "no-repeat", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant="smallstat">1</Typography>
                </Grid>
                <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant="statname" style={{ paddingLeft: '0', paddingRight: '0' }}>{adoData[rowKey][8]}</Typography>
                </Grid>
              </Grid>

              <Grid container style={{ margin: "auto", height: "auto", maxWidth: "250px", paddingBottom: '1vh' }}>
                <Grid item xs={4} style={{ height: '5vh', width: '5vh', backgroundImage: `url(${statRoundel})`, backgroundSize: "contain", backgroundPosition: 'center center', backgroundRepeat: "no-repeat", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant="smallstat">2</Typography>
                </Grid>
                <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant="statname" style={{ paddingLeft: '0', paddingRight: '0' }}>{adoData[rowKey][9]}</Typography>
                </Grid>
              </Grid>

              <Grid container style={{ margin: "auto", height: "auto", maxWidth: "250px", marginBottom: '2vh' }}>
                <Grid item xs={4} style={{ height: '5vh', width: '5vh', backgroundImage: `url(${statRoundel})`, backgroundSize: "contain", backgroundPosition: 'center center', backgroundRepeat: "no-repeat", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant="smallstat">3</Typography>
                </Grid>
                <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant="statname" style={{ paddingLeft: '0', paddingRight: '0' }}>{adoData[rowKey][10]}</Typography>
                </Grid>
              </Grid>

            </div>
          );
        }
        return null;
      })}
      <YouTubeLatestVideo />
    </div>
  );
}

export default Home;