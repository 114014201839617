import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { db } from '../components/firebase/firebase-config.js';
import { doc, getDoc } from '@firebase/firestore';
import usePlayerImage from '../components/usePlayerImage';
import placeholderImage from './photo/placeholder.png';
import playerBackground from './photo/player-background.png';
import statRoundel from '../components/images/stat_roundel.png';
import statContainer from '../components/images/stat_container.png';

const MyComponent = ({ isOpen, handleClose, playerId, playerNickname, playerName, playerDarts, playerWalkOut, playerPhotoPath }) => {

  let playerStats;
  const [statsData, setStatsData] = useState([]);
  const [imageError, setImageError] = useState(false);

  const statsDocumentRef = doc(db, 'ado_database', 'statistics');

  useEffect(() => {
    const getStatsData = async () => {
      try {
        const docSnap = await getDoc(statsDocumentRef);

        if (docSnap.exists()) {
          const documentData = { ...docSnap.data(), id: docSnap.id };
          setStatsData(documentData);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error getting document:', error);
      }
    };
    getStatsData();

  }, []);

  return (
    <Dialog open={isOpen} onClose={handleClose} style={{}}>

      <DialogTitle style={{ padding: '0', backgroundColor: '#003554', color: '#ffffff', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))' }}>
        <Typography variant="smallstat" style={{ padding: '0', fontWeight: '400' }}>Player Profile</Typography>
      </DialogTitle>

      <DialogContent style={{ padding: '0px' }}>

        <Grid container style={{ height: 'auto' }}>

          <Grid container style={{ padding: '1vh', }}>
            <Grid item style={{
              position: 'relative',
              width: '14vh',
              height: 'auto',
              overflow: 'hidden',
              padding: '0',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
              <div style={{
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: "14vh",
                height: '20vh',
                backgroundImage: `url(${playerBackground})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                border: '2px solid #cccccc',
                borderRadius: '5px',
                boxShadow: 'inset 0px 0px 8px rgba(0, 0, 0, 0.2)',
              }}>

                <div style={{ overflow: "hidden", margin: "auto" }}>
                  <ImageLoader fileName={playerPhotoPath} alt={`${playerName}'s Photo`} placeholder={placeholderImage} setImageError={setImageError} />
                </div>
              </div>
            </Grid>

            <Grid item style={{ flex: 1, paddingLeft: '1vh' }}>
              <Typography variant="infoheader">{playerName}</Typography>
              <Typography variant="infobold" style={{ textAlign: "left" }}>Nickname:</Typography>
              <Typography variant="info" style={{ textAlign: "left", paddingLeft: "1vh" }}>{playerNickname}</Typography>
              <Typography variant="infobold" style={{ textAlign: "left" }}>Darts:</Typography>
              <Typography variant="info" style={{ textAlign: "left", paddingLeft: "1vh" }}>{playerDarts}</Typography>
              <Typography variant="infobold" style={{ textAlign: "left" }}>Walk Out:</Typography>
              <Typography variant="info" style={{ textAlign: "left", paddingLeft: "1vh" }}>{playerWalkOut}</Typography>
            </Grid>

          </Grid>
        </Grid>

        <Grid container>

          <Grid item xs={12} style={{ padding: '0', backgroundColor: '#006494', color: '#ffffff', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))' }}>
            <Typography variant="statname">ADO Ranking</Typography>
          </Grid>
          {statsData &&
            Object.values(statsData).map((playerStats) => {
              if (playerStats[0] === playerId) {
                return (
                  <>
                    <Grid container style={{ flex: 1, padding: '1vh', paddingBottom: '0' }}>

                      <Grid container style={{ margin: "auto", height: "auto", width: "auto" }}>
                        <Grid item xs={12} style={{ height: '7vh', width: '7vh', backgroundImage: `url(${statRoundel})`, backgroundSize: "contain", backgroundPosition: 'center center', backgroundRepeat: "no-repeat", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                          <Typography variant="smallstat">{`${playerStats[12]}`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="info">Rank</Typography>
                        </Grid>
                      </Grid>

                      <Grid container style={{ margin: "auto", height: "auto", width: "15vh" }}>
                        <Grid item xs={12} style={{ height: '7vh', width: '15vh', backgroundImage: `url(${statContainer})`, backgroundSize: "contain", backgroundPosition: 'center center', backgroundRepeat: "no-repeat", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                          <Typography variant="smallstat">{`${Math.round(`${playerStats[13]}`).toLocaleString()}`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="info">Prize Points</Typography>
                        </Grid>
                      </Grid>

                    </Grid>

                    <Grid item xs={12} style={{ padding: '0', backgroundColor: '#0582CA', color: '#ffffff', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))' }}>
                      <Typography variant="statname">Match Statistics</Typography>
                    </Grid>

                    <Grid container style={{ padding: '1vh', paddingBottom: '0', flex: 1 }}>

                      <Grid container style={{ margin: "auto", height: "auto", width: "auto" }}>
                        <Grid item xs={12} style={{ height: '7vh', width: '7vh', backgroundImage: `url(${statRoundel})`, backgroundSize: "contain", backgroundPosition: 'center center', backgroundRepeat: "no-repeat", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                          <Typography variant="smallstat">{`${playerStats[2]}`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="info">Won</Typography>
                        </Grid>
                      </Grid>

                      <Grid container style={{ margin: "auto", height: "auto", width: "auto" }}>
                        <Grid item xs={12} style={{ height: '7vh', width: '7vh', backgroundImage: `url(${statRoundel})`, backgroundSize: "contain", backgroundPosition: 'center center', backgroundRepeat: "no-repeat", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                          <Typography variant="smallstat">{`${playerStats[3]}`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="info">Lost</Typography>
                        </Grid>
                      </Grid>

                      <Grid container style={{ margin: "auto", height: "auto", width: "15vh" }}>
                        <Grid item xs={12} style={{ height: '7vh', width: '15vh', backgroundImage: `url(${statContainer})`, backgroundSize: "contain", backgroundPosition: 'center center', backgroundRepeat: "no-repeat", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                          <Typography variant="smallstat" style={{ fontSize: '2.75vh' }}>{`${playerStats[18]}`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="info">Form</Typography>
                        </Grid>
                      </Grid>

                    </Grid>

                    <Grid item xs={12} style={{ padding: '0', backgroundColor: '#00A6FB', color: '#ffffff', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))' }}>
                      <Typography variant="statname">Tournament Performance</Typography>
                    </Grid>

                    <Grid container style={{ margin: 'auto', padding: '1vh' }}>

                      <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Grid container>

                          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography variant="infobold" style={{ textAlign: 'left' }}>Best Tournament:</Typography>
                          </Grid>

                          <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography variant="info" style={{ textAlign: "left", paddingLeft: "1vh" }}>{`${playerStats[16]}`}</Typography>
                          </Grid>

                          <Grid item xs={4} style={{ height: '6vh', width: '15vh', backgroundImage: `url(${statContainer})`, backgroundSize: "contain", backgroundPosition: 'center center', backgroundRepeat: "no-repeat", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography variant="info">{`${playerStats[17]}`}</Typography>
                          </Grid>

                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid container style={{ margin: 'auto', padding: '1vh', paddingTop: '0', paddingBottom: '2vh' }}>

                      <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Grid container>

                          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography variant='infobold' style={{ textAlign: 'left' }
                            } > Last Tournament:</Typography>
                          </Grid>

                          <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography variant="info" style={{ textAlign: "left", paddingLeft: "1vh" }}>{`${playerStats[14]}`}</Typography>
                          </Grid>

                          <Grid item xs={4} style={{ height: '6vh', width: '15vh', backgroundImage: `url(${statContainer})`, backgroundSize: "contain", backgroundPosition: 'center center', backgroundRepeat: "no-repeat", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography variant="info">{`${playerStats[15]}`}</Typography>
                          </Grid>

                        </Grid>
                      </Grid>

                    </Grid>

                  </>
                );
              }
              return null; // Return null if playerStats[0] !== playerId
            })}
        </Grid>
      </DialogContent >
      <DialogActions style={{ backgroundColor: '#051923', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))' }}>
        <Button onClick={handleClose} style={{ backgroundColor: '#051923', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))', right: '0', border: '1px solid #ffffff', borderRadius: '3px' }}>
          <Typography style={{ color: 'white', padding: '0' }}>Close</Typography>
        </Button>
      </DialogActions>
    </Dialog >
  );
};

// Define the ImageLoader component
const ImageLoader = ({ fileName, alt, placeholder, setImageError }) => {
  const { loading, error, image } = usePlayerImage(fileName);

  useEffect(() => { }, [image]);

  if (error)
    return (
      <img
        className="Image"
        src={placeholderImage}
        alt={alt}
        style={{
          margin: 'auto',
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
        }}
      />
    );

  return (
    <>
      {loading ? (
        <Typography>loading</Typography>
      ) : (
        <img
          className="Image"
          src={image}
          alt={alt}
          style={{ margin: 'auto', width: 'auto', height: '20vh', objectFit: 'cover', bottom: '0' }}
        />
      )}
    </>
  );
};

export default MyComponent;