import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { db } from './components/firebase/firebase-config.js';
import { doc, getDoc } from '@firebase/firestore';

function Events() {
  useEffect(() => {
    document.title = 'ADO | Events Calendar';
  }, []);

  const [eventData, setEventData] = useState({});

  // Update the document path
  const eventsDocumentRef = doc(db, 'ado_database', 'events');

  useEffect(() => {
    const getEventData = async () => {
      try {
        const docSnap = await getDoc(eventsDocumentRef);

        if (docSnap.exists()) {
          const documentData = { ...docSnap.data() };
          setEventData(documentData);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error getting document:', error);
      }
    };

    getEventData();
  }, []); // Empty dependency array, so it only runs once when the component mounts

  return (
    <div className="content">
      <Typography variant="header">Upcoming Events</Typography>
      <Typography variant="subheader" style={{marginBottom: "2vh"}}>The full list of planned and upcoming ADO events</Typography>
      {Object.keys(eventData).map((rowKey, index) => (
        <Paper key={rowKey} elevation={3} className={`event_paper row${parseInt(rowKey, 10) + 1}`} style={{height: 'auto',overflow: 'hidden',maxWidth: '450px',marginLeft: 'auto',marginRight: 'auto',marginBottom: '1.5vh',position: 'relative'}}>
          <Grid container>
            <Grid item xs={12} style={{backgroundColor: '#006494', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.25),rgba(0, 0, 0, 0.25))'}}>
              <Typography variant="statname" style={{color: '#ffffff', padding: '1vh'}}>{`${eventData[rowKey][1]}`}</Typography>
            </Grid>
          </Grid>

          <Grid container>

<Grid container style={{ margin: "auto", height: "auto", width: "auto", paddingTop: '1vh', paddingBottom: '1vh'}}>
  <Grid item xs={12}>
        <Typography variant="infobold">Date</Typography>
      </Grid>
      <Grid item xs={12} style={{height: 'auto', width: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Typography variant="info">{eventData[rowKey][2] ? new Date(eventData[rowKey][2].seconds * 1000).toLocaleDateString('en-GB') : 'Invalid Date'}</Typography>
      </Grid>
    </Grid>


<Grid container style={{ margin: "auto", height: "auto", width: "auto", paddingTop: '1vh', paddingBottom: '1vh'}}>
  <Grid item xs={12}>
        <Typography variant="infobold">Venue</Typography>
      </Grid>
      <Grid item xs={12} style={{height: 'auto', width: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Typography variant="info">{`${eventData[rowKey][3]}`}</Typography>
      </Grid>
    </Grid>

    </Grid>
          
          <Grid container style={{flex: 1}}>

            <Grid container style={{ margin: "auto", height: "auto", width: "auto", paddingTop: '1vh', paddingBottom: '1vh'}}>
            <Grid item xs={12}>
                  <Typography variant="infobold">Details</Typography>
                </Grid>
                <Grid item xs={12} style={{height: 'auto', width: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <Typography variant="info">{`${eventData[rowKey][6]}`}</Typography>
                </Grid>
              </Grid>

              <Grid container style={{ margin: "auto", height: "auto", width: "auto", paddingTop: '1vh', paddingBottom: '1vh'}}>
              <Grid item xs={12}>
                  <Typography variant="infobold">Players</Typography>
                </Grid>
                <Grid item xs={12} style={{height: 'auto', width: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <Typography variant="info">{`${eventData[rowKey][4]}`}</Typography>
                </Grid>
              </Grid>
              
              <Grid container style={{ margin: "auto", height: "auto", width: "auto", paddingTop: '1vh', paddingBottom: '1vh'}}>
              <Grid item xs={12}>
                  <Typography variant="infobold">Points Pot</Typography>
                </Grid>
                <Grid item xs={12} style={{height: 'auto', width: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <Typography variant="info">{new Intl.NumberFormat('en-US', {style: 'decimal',minimumFractionDigits: 0,maximumFractionDigits: 0,}).format(eventData[rowKey][5])}</Typography>
                </Grid>
              </Grid>
          
          </Grid>

          <Grid container style={{padding: '1vh'}}>

<Grid item xs={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
    <Typography variant="infobold">Event Description</Typography><Typography variant="info" style={{textAlign: 'left'}}>{`${eventData[rowKey][7]}`}</Typography>
  </Grid>

</Grid>
 
        </Paper>
      ))}
    </div>
  );
}

export default Events;